<template>
	<div class="container">
		<Header :flag="flag" />
		<div class="banner">
			<div class="banner-content">
				 <span class="banner-title">新闻动态</span>
				 <br/>
				 <span class="banner-sub-title">NEWS</span>
			</div>
		</div>
		<div class="main">
			<div class="middel">
				<div class="middle-l">
					<ul>
						<li class="news-list" v-for="(item,index) in list" >
							<div class="news">
								<div class="news-img">
									 <img :src="item.image" />
								</div>
								<div class="news-content">
									<span class="news-title" @click="detail(item,index)">{{item.title}}</span>
									<p class="content">
									  {{item.intro}}
									</p>
									<span class="news-time">{{item.createTime}}</span>
								</div>
							</div>
						</li>
					</ul>
					<div class="pagination-container">
					  <el-pagination
						background
						:hide-on-single-page="onlyOnePage"
						@current-change="handleCurrentChange"
						layout="prev, pager, next"
						:page-size="pageSize"
						:total="total">
					  </el-pagination>
					</div>
				</div>
				<!-- <div class="middle-r">
					<div class="middel-r-content">
						<el-carousel trigger="click" height="376px">
						  <el-carousel-item v-for=" item in carousels">
							  <el-image
								style="width: 340; height: 200; cursor:pointer;"
								
								:src="item.imageUrl"
							  ></el-image>
						  </el-carousel-item>
						</el-carousel>
					</div>
				</div> -->	
			</div>
		</div>
	
	<Footer />	
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default{
		metaInfo: {
			title: '蓝莓科技-新闻动态',
			meta: [
			  {
				name: 'keywords',
				content: '新闻动态,蓝莓科技新闻动态'
			  },
			  {
				name: 'description',
				content: '蓝莓科技(www.lanmeihulian.com)新闻频道，易拓客是国内专业的智能销售服务提供商。我们为企业和个人销售提供从线索挖掘、商机触达、客户管理到成单分析的全流程智能销售解决方案，帮助大中小型企业和个人在销售端降低销售成本，提升销售业绩'
			  }
			]
		},
		name:"News",
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag: 'news',
				list: [
				],
				totalPage: 0,
				pageSize: 10,
				total: 0,
				pageNum: 1,
				onlyOnePage:false,
				carousels: []
			}
		},
		created() {
			this.getList();
			this.getBanner();
		},
		methods:{
			handleCurrentChange(val) {
			  this.pageNum = val;
			  this.getList();
			},
			getBanner(){
			  	this.$http.getBannerList({type:2}).then(res => {
			  		if(res.data!=null){
			  			this.carousels = res.data;
			  		}
			  	})
			},
			getList(){
				this.$http.getNewsList({pageNum:this.pageNum,pageSize:this.pageSize}).then(res => {
					if(res.data!=null){
						this.list = res.data.list;
						this.totalPage=res.data.pages;
						this.total=res.data.total;
						if(res.data.pages<=1){
							this.onlyOnePage=true;
						}
					}
				})
			},
			detail(item,index){
				if(item.link != null && item.link !=''){
					window.open(item.link, '_blank');
				}else{
					this.$router.push({path:'/newsDetail',name:'NewsDetail',query:{id:item.id,t:Date.now()}});
				}
			}
		}
	}
</script>

<style scoped lang="less">
.banner{
	 padding-top: 80px;
	 height: 450px;
	 position: relative;
	 background-image: url(https://file.lanmeihulian.com/news-bg.jpg);
	 background-repeat: no-repeat;
	 background-size: 100% auto;
	 background-position: 50%;
	 .banner-content{
		 width: 1200px;
		 margin: auto;
		 text-align: left;
		 padding-left: 40px;
		 .banner-title{
			 display: inline-block;
			 margin-top: 110px;
			 font-size: 42px;
			 // line-height: 60px;
			 font-weight: 600;
			 color: white;
		 }
		 .banner-sub-title{
			 display: inline-block;
			 font-size: 42px;
			 font-weight: 600;
			 color: white;
		 }
	 }
}	
.main{
	margin-top: 40px;
	width: 1200px;
	margin: auto;
	.middel{
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		justify-content: center;
		.middle-l{
			width: 1200px;
			margin-top: 40px;
			height: auto !important;
			.news-list{
				max-height: 245px;
				height: auto!important;
				.news{
					display: flex;
					display: -webkit-flex;
					flex-direction: row;
					justify-content: center;
					border-bottom: 1px solid #DCDCDC;
					margin-top: 20px;
					padding-bottom: 20px;
					.news-img{
						width: 320px;
						height: 240px;
						img{
							width: 320px;
							height: 240px;
						}
					}
					.news-content{
						display: inline-block;
						vertical-align: top;
						position:relative;
						height: 230px;
						width: 770px;
						margin-left: 39px;
						.news-title{
							display: inline-block;
							color: #333333;
							font-weight: 600;
							padding-top: 10px;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							line-height: 35px;
							font-size: 24px;
							letter-spacing: 0;
							font-weight: 500;
							margin-bottom: 12px;
							transition: color .2s;
						}
						.news-title:hover{
							cursor: pointer;
							color: #218BFB;
						}
						.content{
							.zhaiyao{
								color: #131313;
							}	
							font-size: 18px;
							color:#666666;
							line-height: 30px;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							-o-text-overflow: ellipsis;/* 支持 Opera */ 
							 overflow: hidden;
						}
						.news-time{
							font-size: 20px;
							color: #999999;
							height: 22px;
							letter-spacing: 0;
							line-height: 22px;
							font-weight: 400;
							position:absolute;
							bottom:0;
						}
					}
				}	
			}
			.pagination-container{
				text-align: right;
				width: 100%;
				height: 50px;
				margin-bottom: 40px;
			}
		}
	}
}

</style>
